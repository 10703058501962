<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">Flashcar</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Vous n'êtes pas autorisé à accéder à cette application
        </h2>
        <p class="mb-2">
          Votre abonnement est expiré. Veuillez contacter l'administration
        </p>
        <div class="my-3">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <feather-icon
                  class="text-primary"
                  size="20"
                  icon="PhoneIcon"
              />
            </b-media-aside>
            <b-media-body style="margin-right: 25%">
              <h6 class="mb-0"><a href="tel:+22969507171">+229 69 50 71 71</a></h6>
            </b-media-body>
            <b-media no-body>
              <b-media-aside class="mr-1">
                <feather-icon
                    class="text-primary"
                    size="20"
                    icon="MessageSquareIcon"
                />
              </b-media-aside>
              <b-media-body style="margin-right: 55%">
                <h6 class="mb-0"><a target="_blank" href="mailto:flascar@waouhmonde.com">flascar@waouhmonde.com</a></h6>
              </b-media-body>
            </b-media>
          </b-media>
          <hr />
        </div>
        <b-button
          variant="success"
          class="mb-2 btn-sm-block"
          @click="contact()"
        >
          Renouveller votre abonnement
        </b-button>
        <!-- <b-button
          variant="primary"
          class="mb-2 ml-1 btn-sm-block"
          @click="logout()"
        >
          DECONNEXION
        </b-button> -->

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" style="width: 90%" />
      </div>
    </div>

    <b-modal
        size="sm"
        id="operation-modal"
        content-class="shadow"
        variant="primary"
        v-model="modalinfo"
        title="Renouvellement"
        hide-footer
        centered
    >
      <b-form-group class="col pr-0">
        <validation-provider
            #default="{ errors }"
            name="chargeur"
        >
          <label for="chargeur">Type d'abonnement</label>
          <b-form-select
              id="chargeur"
              v-model="type_abonnement"
              :options="abonnement_types"
              value-field="value"
              @change="ckecktype"
              text-field="text"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group class="col-sm-4 float-sm-right">
        <b-button block  @click="paiement()" variant="primary">
          Payer
        </b-button>
      </b-form-group>
    </b-modal>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BFormSelect,
  BForm,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
//import {openKkiapayWidget} from "kkiapay";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BFormSelect,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BCard,
    BOverlay,
  },
  data() {
    return {
      modalinfo: false,
      informations: {},
      abonnement_types: [
        { text: "Annuel", value: "annuel" },
        { text: "A vie", value: "vie" },
      ],
      amount_paiement:150000,
      type_abonnement:"annuel",
      downImg: require("@/assets/images/pages/unauthorized.jpg"),
    };
  },

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/unauthorized.jpg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  mounted() {
    let host = window.location.host;
    this.$http.get(`/abonnement/${host}`).then((res) => {
      let response = res.data;
      this.informations = response.message;
      if (response.success) {
        localStorage.setItem("subscription", JSON.stringify(response.data));
        this.$router.push({ name: "home" });
      }
      // else {
      // this.informations = response.message;
      // }
    });

  },

  methods: {
    contact() {
      this.modalinfo = true;
    },
    ckecktype(item){
      if (item==="annuel"){
        this.amount_paiement=150000
      }else{
        this.amount_paiement=999900
      }
    },
    paiement(){
  /*    this.modalinfo = false;
      openKkiapayWidget({
        amount: 4000,
        api_key: "1fd074003bf311edafa2d398c4589a54",
        sandbox: true,
        phone: "97000000",
      });*/
      /*openKkiapayWidget({amount:this.amount_paiement,position:"right",callback:"/login",
        data:"",
        theme:"#BF6000",
        sandbox: true,
        api_key:"1fd074003bf311edafa2d398c4589a54"})*/
    }, 
     /* successHandler(response) {
      response.data=window.location.host
         this.$http
               .post('/renouvellement/'+this.type_abonnement,response)
               .then((res) => {
                 this.isLoading = false;
                 if (res.status===200) {
                   this.showToast(res.data, "success");
                 } else {
                   this.showToast("Renouvellement d'abonnement échoué", "danger");
                 }
               })
               .catch((e) => {
                 this.isLoading = false;
               });
         },*/
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    logout() {
      this.$http.post("/logout").then((res) => {
        localStorage.removeItem("flash_token");
        localStorage.removeItem("flash_sc");
        localStorage.removeItem("coco_act");
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
